import axiosInstance from "../AxiosInstance";
import {
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_USERS,
  GET_USERS,
} from "./UserApiEndPoints";


export function getAllUsers(currentPage, limit, search) {
  return axiosInstance.get(GET_USERS + `?page=${currentPage}&limit=${limit}&search=${search}`);
}

export function deleteUser(payload) {
  return axiosInstance.put(DELETE_USERS, payload);
}

export function blockUser(id) {
  
  return axiosInstance.put(BLOCK_USERS+ `?_id=${id}`);
}

export function approveUser(userId) {
  return axiosInstance.post(APPROVE_USER_DETAILS, userId);
}

