import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";

import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { actionJob, getAllJobs } from "../../services/Jobs/JobsService";
import moment from "moment";
import { RangeDatePicker } from "@y0c/react-datepicker";
export default function JobsManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRangePickerKey, setDateRangePickerKey] = useState(1);
  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to select date ////
  function onDateChange(...args) {
    console.log(args, "selected date");
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    setEndDate(args[1] ? moment(args[1]).format("YYYY-MM-DD") : "");
  }
  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllJobs(
        currentPage,
        limit,
        search,
        filter,
        startDate,
        endDate
      );
      console.log(response?.data?.data);
      setUsers(response.data.data);
      const total = response.data.totalRecords;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.totalRecords);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const onAction = async (id, actionType) => {
    setLoader(true);
    try {
      const response = await actionJob(id);
      actionType === true
        ? notifyTopRight("Disabled Successfully.")
        : notifyTopRight("Enabled Successfully.");

      getTableData();
      console.log(response);
    } catch (error) {
      console.log(error.response, "heloo");
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };
  function onReset() {
    setDateRangePickerKey((prev) => prev + 1);
    setStartDate("");
    setEndDate("");
  }
  useEffect(() => {
    getTableData();
  }, [currentPage, endDate]);
  useEffect(() => {
    if (filter) {
      setCurrentPage(0);
      getTableData();
    }
  }, [filter]);
  let timeoutId;

  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // Create a debounced version of the handleFetch function
  const debouncedHandleFetch = debounce(getTableData, 500); // Adjust the delay as needed

  useEffect(() => {
    if (initialFetch === false) {
      // Reset current page when search or status changes
      setCurrentPage(0);
      // Trigger the debounced handleFetch function when search or status changes
      debouncedHandleFetch();
      // Cleanup function
      return () => {
        // Clear any pending API calls if component unmounts or search/status changes again
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Jobs List" motherMenu="Jobs" />
        {startDate && (
          <button
            type="button"
            className="btn btn-primary py-2"
            onClick={() => onReset()}
          >
            Reset Date
          </button>
        )}
      </div>

      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0 search-input"
                      placeholder="Search"
                      // onKeyDown={(e) => {
                      //   console.log(e.key);
                      //   if (e.key === "Enter") {
                      //     handleFetch();
                      //     // setCurrentPage(0);
                      //   }
                      // }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <RangeDatePicker
                key={dateRangePickerKey}
                startText="Start"
                endText="End"
                startPlaceholder="Start Date"
                endPlaceholder="End Date"
                onChange={onDateChange}
                autoFocus
              />

              <div>
                <select
                  className="form-control"
                  //   value={filter}
                  onClick={(e) => setFilter(e.target.value)}
                >
                  <option value={""} hidden>
                    Select..
                  </option>
                  <option value={"NEW"}>New</option>
                  <option value={"INPROGRESS"}>Inprogress</option>
                  <option value={"CANCELLED"}>Cancelled</option>
                  <option value={"COMPLETED"}>Completed</option>
                </select>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>

                    <th>
                      <strong>Customer Name</strong>
                    </th>
                    <th>
                      <strong>company Id</strong>
                    </th>
                    <th>
                      <strong>Address</strong>
                    </th>
                    <th>
                      <strong>Date Created</strong>
                    </th>
                    <th>
                      <strong>Job status</strong>
                    </th>
                    {/* <th>
                      <strong>STATUS</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr
                      key={i}
                      onClick={() =>
                        props.history.push({
                          pathname: "/job-details",
                          state: item._id,
                        })
                      }
                      className="pointer"
                    >
                      <td className="text-wrap" style={{maxWidth:"200px"}}>{item?.name}</td>
                      <td>{item?.customerName}</td>
                      <td>{item?.comapany?.id}</td>
                      <td>{item?.country}</td>
                      <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {item?.status === "NEW" ? (
                          <Badge variant="warning light">
                            {item?.status.charAt(0) +
                              item?.status.slice(1).toLowerCase()}
                          </Badge>
                        ) : item?.status === "INPROGRESS" ? (
                          <Badge variant="primary light">
                            {item?.status.charAt(0) +
                              item?.status.slice(1).toLowerCase()}
                          </Badge>
                        ) : item.status === "CANCELLED" ? (
                          <Badge variant="danger light">
                            {item?.status.charAt(0) +
                              item?.status.slice(1).toLowerCase()}
                          </Badge>
                        ) : (
                          <Badge variant="success light">
                            {item?.status.charAt(0) +
                              item?.status.slice(1).toLowerCase()}
                          </Badge>
                        )}
                      </td>

                      {/* <td>
                        {item?.isBlock ? (
                          <Badge variant="danger light">Disabled</Badge>
                        ) : (
                          <Badge variant="success light">Enabled</Badge>
                        )}
                      </td> */}
                      {/* <td>
                        <Dropdown onClick={(e) => e.stopPropagation()}>
                          <Dropdown.Toggle
                            variant="primary light"
                            className="light sharp btn btn-primary i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item?.isBlock ? (
                              <Dropdown.Item
                                onClick={() => {
                                  onAction(item?._id, false);
                                }}
                              >
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() => onAction(item?._id, true)}
                              >
                                Disable
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
