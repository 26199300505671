/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import jobsIcon from "../../../images/svg/job.svg";
import jobsBlue from "../../../images/svg/jobColored.svg";
import invoice from "../../../images/svg/tax.svg";
import invoiceActive from "../../../images/svg/tax-1.svg";
import { CiMobile4 } from 'react-icons/ci';
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      users = ["user-management"],
      jobs = ["job-management"],
      receipt = ["receipt-management"],
      table = ["tax-management"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                <i className="flaticon-381-networking"></i>

                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link to="user-management" className="ai-icon">
                <i className="flaticon-381-user-9"></i>

                <span className="nav-text">Company Management</span>
              </Link>
            </li>
            <li className={`${jobs.includes(path) ? "mm-active" : ""}`}>
              <Link to="job-management" className="ai-icon">
                {/* <i className="flaticon-381-user-9"></i> */}
                
                <img
                  src={jobs.includes(path) ? jobsBlue : jobsIcon}
                  width={24}
                  // className="mr-4"
                  style={{ marginRight: "20px" }}
                />
                <span className="nav-text">Job Management</span>
              </Link>
            </li>
            <li className={`${table.includes(path) ? "mm-active" : ""}`}>
              <Link to="tax-management" className="ai-icon">
                <i className="flaticon-381-list"></i>

                <span className="nav-text">Tax Management</span>
              </Link>
            </li>
            <li className={`${receipt.includes(path) ? "mm-active" : ""}`}>
              <Link to="receipt-management" className="ai-icon">
              
              {/* <i className="flaticon-381-file"></i> */}

                <img
                  src={receipt.includes(path) ? invoiceActive : invoice}
                  width={22}
                  style={{marginRight:"20px"}}
                />
                <span className="nav-text">Receipt Management</span>
              </Link>
            </li>
            <li className={`${path.includes('app-version-management') ? "mm-active" : ""}`}>
              <Link to="app-version-management">
                <CiMobile4 size={22} strokeWidth={1} style={{marginRight:"20px"}} color={path.includes('app-version-management') ? "" : "gray"}  />
                <span className="nav-text">App Version</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
