import React, { useState, useEffect } from "react";
import axiosInstance from "../../services/AxiosInstance";
import {
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Card,
} from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import PageTitle from "../layouts/PageTitle";

const AppVersionManagement = () => {
  const [versions, setVersions] = useState({
    ios: { version: "", storeUrl: "" },
    android: { version: "", storeUrl: "" },
  });
  const [fetchLoading, setFetchLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    try {
      const [iosResponse, androidResponse] = await Promise.all([
        axiosInstance.get("/appVersion?platform=ios"),
        axiosInstance.get("/appVersion?platform=android"),
      ]);

      setVersions({
        ios: {
          version: iosResponse.data.version || "",
          storeUrl: iosResponse.data.storeUrl || "",
        },
        android: {
          version: androidResponse.data.version || "",
          storeUrl: androidResponse.data.storeUrl || "",
        },
      });
    } catch (error) {
      notifyError("Failed to fetch current versions");
    } finally {
      setFetchLoading(false);
    }
  };

  const validateVersion = (version) => {
    const semverRegex = /^\d+\.\d+\.\d+$/;
    return semverRegex.test(version);
  };

  const handleInputChange = (platform, field) => (e) => {
    const newValue = e.target.value;
    setVersions((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        [field]: newValue,
      },
    }));
  };

  const validateVersions = () => {
    if (!versions.ios.version || !versions.android.version) {
      notifyError("Version fields cannot be empty");
      return false;
    }

    if (!versions.ios.storeUrl || !versions.android.storeUrl) {
      notifyError("Store URL fields cannot be empty");
      return false;
    }

    if (!validateVersion(versions.ios.version)) {
      notifyError("iOS version must follow format: x.x.x (e.g., 1.0.0)");
      return false;
    }

    if (!validateVersion(versions.android.version)) {
      notifyError("Android version must follow format: x.x.x (e.g., 1.0.0)");
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (!validateVersions()) return;

    setSaveLoading(true);
    try {
      await Promise.all([
        axiosInstance.post("/appVersion", {
          platform: "ios",
          version: versions.ios.version.trim(),
          storeUrl: versions.ios.storeUrl.trim(),
        }),
        axiosInstance.post("/appVersion", {
          platform: "android",
          version: versions.android.version.trim(),
          storeUrl: versions.android.storeUrl.trim(),
        }),
      ]);

      notifyTopRight("Success", "App versions updated successfully");
    } catch (error) {
      notifyError("Error updating app versions");
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <div>
      <Col>
        <PageTitle activeMenu="App version management" />
        <Card>
          <Card.Body>
            {fetchLoading ? (
              <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '300px' }}>
                <Spinner animation="border" />
              </div>
            ) : (
              <Form>
                <Row className="mb-4">
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold">iOS Version</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter iOS version"
                        value={versions.ios.version}
                        onChange={handleInputChange("ios", "version")}
                        className="mb-2"
                      />
                      <Form.Text className="text-muted">
                        Version format example: 1.0.0
                      </Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fw-bold">App store url</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter iOS store URL"
                        value={versions.ios.storeUrl}
                        onChange={handleInputChange("ios", "storeUrl")}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold">
                        Android Version
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Android version"
                        value={versions.android.version}
                        onChange={handleInputChange("android", "version")}
                        className="mb-2"
                      />
                      <Form.Text className="text-muted">
                        Version format example: 1.0.0
                      </Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fw-bold">
                        Play store url
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Android store URL"
                        value={versions.android.storeUrl}
                        onChange={handleInputChange("android", "storeUrl")}
                      />
                      </Form.Group>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={saveLoading}
                    className="px-4"
                  >
                    {saveLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mx-2"
                        />
                        Saving...
                      </>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default AppVersionManagement;
