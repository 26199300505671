import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Spinner from "../common/Spinner";
import moment from "moment";
import { CSVLink } from "react-csv";

export default function ReciptDetails(props) {
  const userData = props.location.state.items;
  const [loader, setLoader] = useState(false);

  const flattenData = (data) => {
    return data.map((item, index) => ({
      No: index + 1,
      Item: item.name,
      Type: item.type,
      Units: item.unit,
      Price: item.price,
      "Confidence - Price": item.confidence.price,
      "Confidence - Quantity": item.confidence.quantity,
      "Confidence - Total Price": item.confidence.totalPrice,
      "Confidence - Description": item.confidence.description,
    }));
  };

  const headers = [
    { label: "No", key: "No" },
    { label: "Item", key: "Item" },
    { label: "Type", key: "Type" },
    { label: "Units", key: "Units" },
    { label: "Price", key: "Price" },
    { label: "Confidence - Price", key: "Confidence - Price" },
    { label: "Confidence - Quantity", key: "Confidence - Quantity" },
    { label: "Confidence - Total Price", key: "Confidence - Total Price" },
    { label: "Confidence - Description", key: "Confidence - Description" },
  ];

  return (
    <div>
      <div className="page-titles">
        <h4>Receipt Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="receipt-management">Receipt Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Receipt Details</Link>
          </li>
        </ol>
      </div>
      {loader ? (
        <Spinner />
      ) : (
        <Card className="w-100">
          <Card.Header>
            <h3 className="text-black">Items</h3>
            <CSVLink
              filename={"Receipt-items-file.csv"}
              data={flattenData(userData)}
              headers={headers}
            >
              <button className="btn btn-primary">
                Export <i class="flaticon-381-send"></i>
              </button>
            </CSVLink>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>No.</strong>
                  </th>
                  <th>
                    <strong>Item</strong>
                  </th>
                  <th>
                    <strong>Type</strong>
                  </th>
                  <th>
                    <strong>Units</strong>
                  </th>
                  <th>
                    <strong>Price</strong>
                  </th>
                  <th>
                    <strong>Confidence</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData &&
                  userData?.map((item, i) => (
                    <tr key={i}>
                      <td style={{ maxWidth: "50px" }}>{i + 1}</td>
                      <td style={{ maxWidth: "50px" }}>{item?.name}</td>
                      <td style={{ maxWidth: "50px" }}>{item?.type}</td>
                      <td style={{ maxWidth: "50px" }}>{item?.unit}</td>
                      <td style={{ maxWidth: "50px" }}>${item?.price}</td>
                      <td style={{ maxWidth: "50px" }}>
                        <b>Price -</b> {item?.confidence?.price}
                        <br />
                        <b>Quantity -</b> {item?.confidence?.quantity}
                        <br />
                        <b>Total Price -</b>
                        {item?.confidence?.totalPrice}
                        <br />
                        <b>Description -</b>
                        {item?.confidence?.description}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {userData?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
