import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { viewJob } from "../../services/Jobs/JobsService";
import Spinner from "../common/Spinner";
import moment from "moment";

export default function JobDetails(props) {
  const id = props.location.state;
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await viewJob(id);
      setUserData(response.data.data);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, []);
  return (
    <div>
      <div className="page-titles">
        <h4>Job Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="job-management">Job Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Job Details</Link>
          </li>
        </ol>
      </div>
      {loader ? (
        <Spinner />
      ) : (
        <Card className="w-100">
          <Card.Header className="d-block">
            <div className="text-left">
              <h3 className="text-black ">{userData?.name} </h3>
              <p className="mb-0">{userData?.email}</p>
              <p className="mb-0">
                {userData?.countryCode ? "+" + userData?.countryCode : ""}{" "}
                {userData?.number}
              </p>
              <p className="mb-0">{userData?.address}</p>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="">
              <div className="pb-3">
                <h4 className="text-black">Company</h4>
                <p className="mb-0"><b>Id - </b>{userData?.comapany?.id}</p>
                <p className="mb-0"><b>Name - </b>{userData?.comapany?.name}</p>
                <p className=""><b>Email - </b>{userData?.comapany?.email}</p>
              </div>
              <div className="pb-3">
                <h4 className="text-black">Customer Name</h4>
                <p className="">{userData.customerName}</p>
              </div>
              <div className="pb-3">
                <h4 className="text-black">Description</h4>
                <p className="">{userData.description}</p>
              </div>
              <div className="pb-3">
                <h4 className="text-black">Date Created</h4>
                <p className="">
                  {moment(userData.createdAt).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="pb-3">
                <h3 className="text-black">Inventory</h3>
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>No.</strong>
                      </th>
                      <th>
                        <strong>Item</strong>
                      </th>
                      <th>
                        <strong>Units</strong>
                      </th>
                      <th>
                        <strong>Price</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.inventory &&
                      userData?.inventory?.map((item, i) => (
                        <tr key={i}>
                          <td style={{ maxWidth: "50px" }}>{i + 1}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.name}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.unit}</td>
                          <td style={{ maxWidth: "50px" }}>${item?.price}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {userData?.inventory?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3">
                <h3 className="text-black">Material</h3>
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>No.</strong>
                      </th>
                      <th>
                        <strong>Item</strong>
                      </th>
                      <th>
                        <strong>Units</strong>
                      </th>
                      <th>
                        <strong>Price</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.material &&
                      userData?.material?.map((item, i) => (
                        <tr key={i}>
                          <td style={{ maxWidth: "50px" }}>{i + 1}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.name}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.unit}</td>
                          <td style={{ maxWidth: "50px" }}>${item?.price}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {userData?.material?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3">
                <h3 className="text-black">Time</h3>
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>No.</strong>
                      </th>
                      <th>
                        <strong>Item</strong>
                      </th>
                      <th>
                        <strong>Units</strong>
                      </th>
                      <th>
                        <strong>Price</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.time &&
                      userData?.time?.map((item, i) => (
                        <tr key={i}>
                          <td style={{ maxWidth: "50px" }}>{i + 1}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.name}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.unit}</td>
                          <td style={{ maxWidth: "50px" }}>${item?.price}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {userData?.time?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3">
                <h3 className="text-black">Tooling Charge</h3>
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>No.</strong>
                      </th>
                      <th>
                        <strong>Item</strong>
                      </th>
                      <th>
                        <strong>Units</strong>
                      </th>
                      <th>
                        <strong>Price</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.tooling &&
                      userData?.tooling?.map((item, i) => (
                        <tr key={i}>
                          <td style={{ maxWidth: "50px" }}>{i + 1}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.name}</td>
                          <td style={{ maxWidth: "50px" }}>{item?.unit}</td>
                          <td style={{ maxWidth: "50px" }}>${item?.price}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {userData?.tooling?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="pb-3">
                <h3 className="text-black">Receipts</h3>
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>No.</strong>
                      </th>
                      <th>
                        <strong>Item</strong>
                      </th>
                      <th>
                        <strong>Units</strong>
                      </th>
                      <th>
                        <strong>Price</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.receipt &&
                      userData?.receipt?.map((item, i) => (
                        <tr key={i}>
                          <td style={{ maxWidth: "50px" }}>{i + 1}</td>
                          <td style={{ maxWidth: "50px" }}><a href={item?.name} target="_blank" rel="noreferrer">{item?.name.substring(item?.name.lastIndexOf("/") + 1)}</a></td>
                          <td style={{ maxWidth: "50px" }}>{item?.unit}</td>
                          <td style={{ maxWidth: "50px" }}>${item?.price}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {userData?.receipt?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
