import axiosInstance from "../AxiosInstance";
import { GET_TAX, POST_TAX, UPDATE_TAX } from "./TaxEndpoints";

export function postTexData(formData) {
  return axiosInstance.post(POST_TAX, formData);
}

export function getTexData(currentPage, limit, startDate, endDate) {
  return axiosInstance.get(
    GET_TAX +
      `?page=${currentPage}&limit=${limit}&fromDate=${startDate}&toDate=${endDate}`
  );
}

export function updateTaxData(formData, id) {
  return axiosInstance.put(UPDATE_TAX + `?_id=${id}`, formData);
}
