import axiosInstance from "../AxiosInstance";
import { BLOCK_JOBS, GET_JOBS, GET_RECEIPT, VIEW_JOBS } from "./JobEndPoints";

export function getAllJobs(
  currentPage,
  limit,
  search,
  filter,
  startDate,
  endDate
) {
  return axiosInstance.get(
    GET_JOBS +
      `?page=${currentPage}&limit=${limit}&search=${search}&status=${filter}&fromDate=${startDate}&toDate=${endDate}`
  );
}

export function viewJob(id) {
  return axiosInstance.get(VIEW_JOBS + `?_id=${id}`);
}

export function actionJob(id) {
  return axiosInstance.put(BLOCK_JOBS + `?_id=${id}`);
}

// export function getReceipt(
//   currentPage,
//   limit,
//   startDate,
//   endDate,
//   sort,
//   countrySort,
//   nameSort
// ) {
//   return axiosInstance.get(
//     GET_RECEIPT +
//       `?page=${currentPage}&limit=${limit}&fromDate=${startDate}&toDate=${endDate}&sort=${sort}`
//   );
// }

export function getReceipt(
  currentPage,
  limit,
  search,
  startDate,
  endDate,
  sort,
  countrySort,
  nameSort
) {
  let queryParams = `?page=${currentPage}&limit=${limit}&search=${search}&fromDate=${startDate}&toDate=${endDate}`;

  if (sort) {
    queryParams += `&sort=${sort}`;
  }

  if (countrySort) {
    queryParams += `&countrySort=${countrySort}`;
  }

  if (nameSort) {
    queryParams += `&nameSort=${nameSort}`;
  }

  return axiosInstance.get(GET_RECEIPT + queryParams);
}
